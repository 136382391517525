.receive-content {
    height: 100%;

    .filesize {
        font-style: italic;
        vertical-align: top;
    }
}

.download {
    float: right;
    padding-right: 8px;

    .download-icon {
        height: 30px;
        width: 30px;
        cursor: pointer;
    }

    .download-progress {
        font-style: italic;
    }
    
    .hidden {
        display: none !important;
    }

}

.message.error-message {
    width: fit-content;
    padding: 0 12px;
    margin-left: 12px;
}
