@import './theme-variables.scss';

@font-face {
    font-family: 'Merriweather Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Merriweather Regular'), url('../fonts/Merriweather-Regular.ttf') format('truetype');
}

body {
    font-family: 'Merriweather Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $charcoal;
    cursor: default;
    margin: 0;
}


.button {
    width: 160px;
    height: 43px;
    background-color: $button-background;
    color: $desert-storm;
    border-style: none;
    border-color: $button-border;
    border-radius: 2px;
    font: inherit;
    cursor: pointer;

    &:hover {
        background-color: $button-hover;
    }
}

a {
    color: inherit;
    text-decoration: inherit;
}

.modal {
    .overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        display: block;
        background: rgba(0,0,0,.5);
        z-index: 10;
        top: 0;
        left: 0;
    }

    .modal-dialog {
        z-index: 11;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $dialog-background;
        padding: 40px 20px 20px 20px;
        text-align: center;

        &::before {
            content: "";
            background: $dialog-header;
            height: 20px;
            width: 100%;
            transform: translateX(-50%);
            position: absolute;
            top: 0;
        }

        .button {
            margin-top: 24px;
            margin-bottom: 10px;
        }

        .cancel-link {
            text-decoration: underline;
            cursor: pointer;
        }
    }

}

.checkmark::before {
    content: "\2714";
    color: $checkmark-color;
    font-size: 30px;
}

.cancel::before {
    content: '\2716';
    color: $cancel-color;
    font-size: 30px;
}

.icon {
    cursor: pointer;
}

.content, .settings-content, .send-content, .receive-content {
    .message {
        display: inline-block;
        width: 300px;
        text-align: center;
        line-height: 32px;

        &.error-message {
            background-color: #f8d7da;
            color: #721c24;
            border: 1px #f5c6cb solid;
        }

        &.info-message {
            background-color: #d1ecf1;
            color: #0c5460;
            border: 1px #bee5eb solid;
        }

        &.success-message {
            background-color: #d4edda;
            color: #155724;
            border: 1px #c3e6cb solid;
            opacity: 1;
            transition: opacity 1s;
            -webkit-transition: opacity 1s;
        }
        
        &.spinner {
            border: 10px solid $spinner-container;
            border-top: 10px solid $spinner-color;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 1s linear infinite;

            &.spinner-small {
                width: 10px;
                height: 10px;
                margin: 0 12px;
            }
        }

        &:not(.spinner) {
            display: flex;
            justify-content: center;
        }
        
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

    }
}

