.settings-content {
    .settings-header {
        font-size: 26px;
    }

    .setting {
        padding: 24px 0px;

        .label {
            padding-bottom: 8px;
        }

        input {
            min-width: 400px;
        }
    }

    .button {
        margin-top: 48px;
    }
}