@import '../../constants/styles/theme-variables.scss';

.progress-bar {
    display: inline-block;
    width: 80%;
    margin-top: 5px;

    .outer-container {
        height: 10px;
        background-color: $progress-bar-container;
        border-radius: 50px;
        margin-right: 20px;

        &.unknown {
            background: repeating-linear-gradient(
                -45deg,
                $progress-bar-unknown-color,
                $progress-bar-unknown-color 5px,
                $progress-bar-color  5px,
                $progress-bar-color 10px
            );
            background-size: 200% 200%;
            animation: barberpole 20s linear infinite;
        }

        @keyframes barberpole {
            100% {
                background-position: 100% 100%;
            }
        }

        .inner-container {
            height: 100%;
            background-color: $progress-bar-color;
            border-radius: inherit;
            transition: 1s ease;
        }
    }

    .state {
        margin-top: 5px;

        .current-stage {
            font-style: italic;
        }

        .right {
            float: right;
            margin-right: 20px;

            
        }

    }
}