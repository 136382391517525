@import '../../constants/styles/theme-variables.scss';

.header-menu > img {
    cursor: pointer;
}

.user-icon {
    height: 30px;
    width: 30px;
    right: 0px;
}

.dropdown {
    position: relative;
    top: 50px;
    .arrow-up {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $menu-color;
        float: right;
        margin-top: -12px;
    }

    .dropdown-content {
        color: $charcoal;
        text-align: center;
        background-color: $menu-color;
        width: 250px;
        height: 200px;

        > .user-icon {
            margin: 12px;
        }
    }
}

.options {
    text-align: left;
    margin: 12px 24px;

    .option {
        padding: 12px 0px;
        border-top: $menu-option-border 1px solid;

        &:hover {
            color: $coffee;
            font-weight: bold;
        }
    }
}