@import '../../constants/styles/common.scss';
@import './Section.scss';

.main {
    height: 100vh;
}

.header {
    background-color: $header-background;
    color: $header-color;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;

    a {
        display: contents;
    }

    .icon-logo {
        width: 50px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .product-name {
        font-size: 32px;
        line-height: 100px;
    }

    .account-menu {
        position: absolute;
        right: 24px;
        top: 35px;
        display: flex;
        align-items: center;
    }
}

.options {
    text-align: left;
    margin: 12px 24px;

    .option-header {
        padding: 24px 0px;
        color: $coffee;
        font-weight: bold;
        border-top: $menu-option-border 2px solid;
    }

    .option {
        cursor: pointer;
        padding: 12px 0px;
        border-top: $menu-option-border 1px solid;

        &:hover {
            color: $menu-option-hover-color;
            font-weight: bold;
        }

        &.hidden {
            display: none;
        }

        &.sub-option {
            padding-left: 12px;
        }

        &.open {
            color: $coffee;
            font-weight: bold;
            border-top: $menu-option-border 2px solid;
        }
    }
}

.menu {
    background-color: $menu-color;
    height: 100%;
    width: 200px;
    position: absolute;
    top: 0;

    .options {
        margin-top: 100px;
        font-size: 16px;

        .option:last-child {
            border-bottom: $menu-option-border 1px solid;
        }
    }

    .main-logo {
        width: 150px;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        position: absolute;
    }
}


.main-content {
    position: absolute;
    left: 200px;
    top: 100px;
    padding: 50px 40px;
    width: calc(100% - 280px);
    height: calc(100% - 200px);

    .content-header {
        font-size: 26px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 30px;
    }
}
