@import '../../constants/styles/common.scss';

.login-background {
    background-color: $login-background;
    color: $login-font-color;
    min-height: 100vh;
    font-size: 16px;
}

.login-footer {
    background-color: $login-footer;
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;

    .main-logo {
        width: 200px;
    }
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .product-name {
        font-size: 48px;
        margin: 12px;
    }

    .icon-logo {
        height: 100px;
    }

    .form {
        ::placeholder {
            color: $input-placeholder-font-color;
        }

        input:not([type="submit"]) {
            width: 400px;
            height: 48px;
            font-size: 12px;
            margin-top: 32px;
            padding-left: 10px;
            font: inherit;

            &:focus {
                outline: none;
            }
        }

        .button[type="submit"] {
            margin-top: 32px;
        }
    }

    .google-button {
        display: inline-block !important;
        margin-top: 40px;
    }

    .message {
        margin-top: 32px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &.spinner {
            left: calc(50% - 15px); // transform doesn't work because of animation
        }
    }
}