@import '../../constants/styles/theme-variables.scss';

.section {
    height: 45%;
    margin-bottom: 30px;

    .section-content{
        border: $section-border 1px solid;
        border-radius: 5px;
        height: 80%;
        margin: 30px 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex-flow: column;

        thead, tbody {
            display: block;
            width: 100%;
        }

        tbody {
            overflow-y: auto;
            height: 100%;
        }

        tbody::before {
            content: '';
            height: 0px;
        }

        tr {
            border-bottom: $section-border 1px solid;
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;

            th, td {
                flex: 1;
                text-align: left;
                padding-left: 16px;
                line-height: 30px;

                &.status {
                    flex: 2;
                }

                &.activity-time {
                    text-align: right;
                    padding-right: 16px;
                    font-style: italic;
                }

            }
        }
    }
}