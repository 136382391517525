@import '../../constants/styles/theme-variables.scss';

.send-content {
    height: 100%;

    .recipients {
        .recipient-list {
            background: none;
            min-width: 300px;
            max-width: 600px;
            font: inherit;
            margin-left: 8px;
            padding: 4px 2px;
    
            .disabled {
                opacity: .5;
            }
        }
    
        .message.error-message {
            display: inline-block;
            width: fit-content;
            padding: 0 12px;
            margin-left: 12px;
        }

    }


    .dropzone {
        height: 100%;
        text-align: center;
        cursor: pointer;

        &.disabled {
            pointer-events: none;
        }

        .messages {
            top: 50%;
            position: relative;
            vertical-align: middle;
            transform: translateY(-50%);
            color: $dropzone-font-color;

            .files-icon {
                width: 100px;
                height: 100px;
            }

            .click-message {
                font-size: 12px;
                padding-top: 24px;
                color: $dropzone-click-font-color;
            }
        }
    }

    .filesize {
        font-style: italic;
        vertical-align: top;
    }
}

.send-new-files {
    float: right;
    transform: translateY(-50%);
}


.close-link {
    float: right;
}
