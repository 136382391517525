// Theme colors
$indigo: #1c3956;
$coffee: #af8f61;
$creme: #dfddd0;
$desert-storm: #f7f6f5;

// Additional colors
$charcoal: #333333;
$light-grey: #d9d9d9;
$grey: #cccccc;
$dark-grey: #a9a9a9;
$lime-green: #6aca25;

$grey-black: #282828;
$steel-grey: #404040;
$light-steel-grey: #5e5e5e;