@import '../../constants/styles/theme-variables.scss';

.settings-content {
    height: 60%;

    .settings-linked-accounts {
        height: 100%;

        .settings-header {
            font-size: 26px;
        }
        .settings-message {
            font-style: italic;
        }
        .form {
            display: contents;
        }
        .section-content {
            th:not(.empty), td:not(.empty) {
                flex: 3;
            }

            td.empty {
                position: relative;
                display: flex;

                > * {
                    float: right;
                    padding: 0 30px;
                }

                .trash-icon {
                    width: 30px;
                }

                .submit {
                    display: none;
                }
            }
        }
    }

    .add-new {
        border-radius: 24px;
        float: right;
        margin: 0;
        font-size: 24px;
        width: 48px;
        height: 48px;
        background-color: $add-button-color;
        font-weight: bold;

        &:focus {
            border: none;
        }
    }

    
}